import React, { useContext, useEffect, useState } from 'react';
import { SidebarItem } from '@vtblife/layout-config/types';
import cn from 'classnames';
import { Button, Link, Typography } from '@vtblife/uikit';

import './user-info.module.css';
import { LoginButton } from '../login-button/login-button';
import { UserInfoMenuButton } from './user-info-menu-button/user-info-menu-button';
import { UserInfoMenuItem } from './user-info-menu-item/user-info-menu-item';
import { UserInfoMenuContentHeader } from './user-info-menu-content-header/user-info-menu-content-header';
import { HeaderContextValue } from '../header-context';
import { MenuItemProps, MenuLink } from '../menu-link/menu-link';
import { NewsContainer } from '../../news-container/news-container';
import { useAuthorizeContext, useUpdatesContext } from '../../shell/contexts';
import { mapUpdatesToSidebar } from '../../utils';
import { FreshDot } from '../../fresh-dot/fresh-dot';

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    initialUnauthorizedUser: 'initialUnauthorizedUser',
    initialAuthorizedUser: 'initialAuthorizedUser',
    unauthorizedUser: 'unauthorized',
    authorizedUser: 'authorized',
    userInfoDesktop: 'userInfoDesktop',
    userInfoMobile: 'userInfoMobile',
    userInfoMobileGroupTitle: 'userInfoMobileGroupTitle',
    userInfoMobileLogin: 'userInfoMobileLogin',
    userInfo: 'userInfo',
    userInfoMenu: 'userInfoMenu',
    userInfoMenuTransparent: 'userInfoMenuTransparent',
    userInfoMenuButtonDesktop: 'userInfoMenuButtonDesktop',
    userInfoMenuButtonMobile: 'userInfoMenuButtonMobile',
    userInfoMenuContent: 'userInfoMenuContent',
    userInfoMenuContentOpened: 'userInfoMenuContentOpened',
    userInfoMenuContentInner: 'userInfoMenuContentInner',
    separator: 'separator',
};

const userAuthorizationStateStyleId = 'user-authorization-state-style';

const MenuItemComponent: React.FC<MenuItemProps> = (props) => {
    if (props.item.id == 'news') {
        return (
            <NewsContainer variant="menu">
                <MenuLink {...props} />
            </NewsContainer>
        );
    }

    if (props.item.freshCount !== undefined && props.item.freshCount > 0) {
        return (
            <FreshDot variant="menu" isCircleVisible>
                <MenuLink {...props} />
            </FreshDot>
        );
    }

    return <MenuLink {...props} />;
};

const AuthorizedItemsList: React.FC<{ pathname?: string; list: SidebarItem[] }> = ({ pathname, list }) => {
    return (
        <>
            {list.map((item: SidebarItem) => (
                <div key={item.displayName} className={styles.userInfoMobile} data-test="user-info-mobile">
                    <MenuItemComponent key={item.id} item={item} pathname={pathname} isOpenByDefault={true} />
                </div>
            ))}
        </>
    );
};

export const UserInfo = () => {
    const { isAuthorized } = useAuthorizeContext();
    const { isUserInfoMenuOpened, authorizedItems, loginText, mobileLoginText, pathname, isTransparent } =
        useContext(HeaderContextValue);
    const { loginPath, personalAreaPath } = useContext(HeaderContextValue);
    const [isBrowser, setBrowser] = useState(false);

    useEffect(() => {
        const initialAuthorizationStyles = document.querySelector(`#${userAuthorizationStateStyleId}`);
        initialAuthorizationStyles?.remove();
        setBrowser(true); // for correct dehydration
    }, []);

    const { servicesUpdates } = useUpdatesContext();

    const authorizedItemsMapped = React.useMemo(
        () => ({
            items: mapUpdatesToSidebar(authorizedItems?.items || [], servicesUpdates),
            footerItems: mapUpdatesToSidebar(authorizedItems?.footerItems || [], servicesUpdates),
            grouped: authorizedItems?.groupedItems?.map((groupedItem) => ({
                name: groupedItem.name,
                items: mapUpdatesToSidebar(groupedItem.items || [], servicesUpdates),
            })),
        }),
        [authorizedItems, servicesUpdates],
    );

    return (
        <>
            <span
                className={cn(styles.initialUnauthorizedUser, {
                    [styles.unauthorizedUser]: isBrowser && !isAuthorized,
                })}
            >
                <span className={styles.userInfoDesktop} data-test="desktop-login">
                    <a className={styles.userInfo} href={loginPath}>
                        <LoginButton text={loginText} />
                    </a>
                </span>
                <span className={styles.userInfoMobileLogin} data-test="mobile-login">
                    <Link href={loginPath}>
                        <Button variant="secondary" fullWidth>
                            {mobileLoginText}
                        </Button>
                    </Link>
                </span>
            </span>
            <div
                className={cn(styles.userInfoMenu, styles.initialAuthorizedUser, {
                    [styles.userInfoMenuTransparent]: isTransparent,
                    [styles.authorizedUser]: isBrowser && isAuthorized,
                })}
                data-test="user-info-menu"
            >
                <div className={styles.userInfoMenuButtonDesktop}>
                    <UserInfoMenuButton path={personalAreaPath} url={personalAreaPath} />
                </div>
                <UserInfoMenuContentHeader />
                <div
                    className={cn(styles.userInfoMenuContent, {
                        [styles.userInfoMenuContentOpened]: isUserInfoMenuOpened,
                    })}
                >
                    <div className={styles.userInfoMenuContentInner}>
                        <AuthorizedItemsList list={authorizedItemsMapped.items} pathname={pathname} />
                        {authorizedItemsMapped.grouped?.map((groupedItem) => (
                            <>
                                <Typography
                                    className={styles.userInfoMobileGroupTitle}
                                    bold
                                    variant="secondary"
                                    color="secondary"
                                >
                                    {groupedItem.name}
                                </Typography>
                                <AuthorizedItemsList list={groupedItem.items} pathname={pathname} />
                            </>
                        ))}
                        {authorizedItemsMapped.items
                            ?.reduce((arr, item) => {
                                if (item.dropdownMenu) {
                                    arr.push(...item.dropdownMenu);
                                    return arr;
                                }
                                arr.push(item);
                                return arr;
                            }, [] as SidebarItem[])
                            ?.filter((item) => item.showInDesktopProfileMenu)
                            ?.map((item: SidebarItem) => (
                                <div key={item.displayName} className={styles.userInfoDesktop}>
                                    <UserInfoMenuItem key={item.id} item={item} />
                                </div>
                            ))}
                        <hr className={styles.separator} />
                        {authorizedItemsMapped.footerItems?.map((item: SidebarItem) => (
                            <UserInfoMenuItem key={item.id} item={item} dataTest="user-info-footer-item" />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
