import { useEffect, useState } from 'react';
import { getWalletBalance } from '@vtblife/billing-api/axios-gen/billing_service';
import { AxiosError } from '@vtblife/axios';

import { reportErrorToSentry, useAuthorizeContext } from '../shell';

export const useDisplayWallet = () => {
    const { user } = useAuthorizeContext();
    const [walletBalance, setWalletBalance] = useState<number | null>(null);

    const { currentRoleType, companyId, currentRole, permissions } = user || {};
    const hasWallet = permissions?.includes('/billing/wallet/loyalty:read');
    const isRealtorEmpowered = currentRoleType === 'REALTOR' && currentRole === '37';
    const shouldDisplayWallet = isRealtorEmpowered && companyId && hasWallet;

    // Если amount === null, то это означает, что не пришел баланс кошелька
    // Если amount === 0, то это значит, что есть кошелёк с нулевым балансом
    const walletLink = walletBalance === null ? `/billing/${companyId}` : `/billing/${companyId}/loyalty/details`;
    const walletText = walletBalance === null ? 'Кошельки' : walletBalance.toLocaleString('ru') + ' ₽';

    useEffect(() => {
        if (shouldDisplayWallet) {
            getWalletBalance({ params: { product: 'LOYALTY', companyId: companyId } })
                .then((response) => {
                    if ('balance' in response) {
                        const numericBalance = Number(response.balance || 0) / 100;
                        setWalletBalance(numericBalance);
                    }
                })
                .catch((error: AxiosError) => {
                    // @TODO: remove 404 check after billing backend release (Если приходит 404 ошибка, то это значит, что у юзера всего лишь нет кошелька. Её нужно игнорить)
                    if (!(error.isAxiosError && error.response?.status === 404)) {
                        reportErrorToSentry({ error });
                    }
                });
        }
    }, [shouldDisplayWallet, companyId]);

    return { shouldDisplayWallet, walletBalance, walletLink, walletText };
};
